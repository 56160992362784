import React, { useEffect, useState } from "react";
import Header from "../../Shared/Header";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";

const COLORS = [
  "#f0ad4e", // Yellow - warning (mild risk)
  "#ff9800", // Orange - elevated (moderate risk)
  "#4caf50", // Green - healthy (optimal range)
  "#dc3545", // Red - high concern (high risk)
  "#b22222", // Dark Red - critical (severe risk)
];

// Define ranges for different metrics globally
const systolicBPRanges = [
  { min: 0, max: 59, color: COLORS[0] },
  { min: 60, max: 79, color: COLORS[1] },
  { min: 80, max: 105, color: COLORS[2] },
  { min: 106, max: 130, color: COLORS[2] },
  { min: 131, max: 160, color: COLORS[3] },
  { min: 161, max: 200, color: COLORS[4] },
];

const diastolicBPRanges = [
  { min: 0, max: 30, color: COLORS[0] },
  { min: 31, max: 49, color: COLORS[1] },
  { min: 50, max: 70, color: COLORS[2] },
  { min: 71, max: 90, color: COLORS[2] },
  { min: 91, max: 100, color: COLORS[3] },
  { min: 101, max: 120, color: COLORS[4] },
];

const heartRateRanges = [
  { min: 0, max: 29, color: COLORS[0] },
  { min: 30, max: 59, color: COLORS[1] },
  { min: 60, max: 75, color: COLORS[2] },
  { min: 76, max: 100, color: COLORS[2] },
  { min: 101, max: 120, color: COLORS[3] },
  { min: 121, max: 150, color: COLORS[4] },
];

const breathingRateRanges = [
  { min: 0, max: 4, color: COLORS[0] },
  { min: 5, max: 11, color: COLORS[1] },
  { min: 12, max: 15, color: COLORS[2] },
  { min: 16, max: 20, color: COLORS[2] },
  { min: 21, max: 30, color: COLORS[3] },
  { min: 31, max: 40, color: COLORS[4] },
];

const hrvRanges = [
  { min: 0, max: 19, color: COLORS[0] },
  { min: 20, max: 49, color: COLORS[1] },
  { min: 50, max: 75, color: COLORS[2] },
  { min: 76, max: 110, color: COLORS[2] },
  { min: 111, max: 160, color: COLORS[3] },
  { min: 161, max: 200, color: COLORS[4] },
];

const bmiRanges = [
  { min: 0, max: 14.9, color: COLORS[0] },
  { min: 15, max: 18.4, color: COLORS[1] },
  { min: 18.5, max: 24.9, color: COLORS[2] },
  { min: 25, max: 29.9, color: COLORS[3] },
  { min: 30, max: 39.9, color: COLORS[3] },
  { min: 40, max: 50, color: COLORS[4] },
];

const stressIndexRanges = [
  { min: 0, max: 50, color: COLORS[2] },    // 0% - 50% (Normal)
  { min: 51, max: 75, color: COLORS[3] },   // 51% - 75% (Elevated)
  { min: 76, max: 100, color: COLORS[4] },  // 76% - 100% (High Stress)
];



const oxygenLevelRanges = [
  { min: 90, max: 94, color: COLORS[3] },
  { min: 95, max: 100, color: COLORS[2] },
];

const GaugeCard = ({
  title,
  value,
  minValue,
  maxValue,
  ranges,
  unit,
  status,
  healthyRange,
}) => {
  const getColorForValue = (value) => {
    for (let i = 0; i < ranges.length; i++) {
      const range = ranges[i];
      if (value >= range.min && value <= range.max) {
        return range.color;
      }
    }
    return "#b22222"; // Default color if not found
  };

  const isStressIndex = title === "Stress Index";

  let gaugeValue;
  let displayValue;
  let adjustedMin = minValue;
  let adjustedMax = maxValue;

  if (isStressIndex) {
    // Calculate percentage out of 6 and round off
    gaugeValue = Math.round((value / 6) * 100);
    displayValue = gaugeValue; // We'll add the % symbol in the render function
    adjustedMin = 0;
    adjustedMax = 100;
  } else {
    gaugeValue = value;
    displayValue = value;
  }

  const color = getColorForValue(gaugeValue);

  return (
    <div className="bg-white shadow-md rounded-lg p-6 text-center border-none flex flex-col items-center ">
      <h3 className="text-lg font-semibold mb-3 text-primaryColor border-b border-gray-300 pb-2 w-full text-center ">
        {title}
      </h3>
      <div
        style={{
          width: 200,
          height: 200,
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "20px",
          boxShadow: `0 0 20px 10px ${color}80`,
          borderRadius: "50%",
        }}
      >
        <Gauge
          value={gaugeValue}
          min={adjustedMin}
          max={adjustedMax}
          thickness={18}
          sx={{
            [`& .${gaugeClasses.valueText}`]: {
              fontSize: "35px",
              fill: color,
              fontWeight: "bold",
              dominantBaseline: "middle",
              textAnchor: "middle",
              alignmentBaseline: "central",
            },
            [`& .${gaugeClasses.valueArc}`]: {
              fill: color,
              filter: `drop-shadow(0 0 20px ${color}80)`,
            },
            [`& .${gaugeClasses.referenceArc}`]: {
              fill: "#e0e7ff",
            },
          }}
          // Use renderValueLabel to customize the value display
          renderValueLabel={() => (
            <text
              x="50%"
              y="50%"
              fill={color}
              fontSize="35px"
              fontWeight="bold"
              textAnchor="middle"
              dominantBaseline="central"
            >
              {isStressIndex ? `${displayValue}%` : displayValue}
            </text>
          )}
        />
      </div>
      {unit && !isStressIndex && (
        <p className="text-lg font-semibold mt-8 text-primaryColor ">
          {unit}
        </p>
      )}
      <p className="text-sm text-secondaryColor mt-4">
        Healthy Range: <strong>{healthyRange}</strong>
      </p>
      <button className="mt-3 text-primaryColor px-5 py-2 rounded-full bg-gray-100 shadow-sm text-lg hover:bg-gray-200">
        {status}
      </button>
    </div>
  );
};





const SmartVitalsReport = (props) => {
  const [vitalsData, setVitalsData] = useState(null);

  useEffect(() => {
    if (props.location.state && props.location.state.responseData) {
      setVitalsData(props.location.state.responseData);
    }
  }, [props.location.state]);

  if (!vitalsData) {
    return <div className="text-center p-6">Loading vitals data...</div>;
  }

  return (
    <div className="p-4 bg-gray-50 min-h-screen mt-28">
      <Header />
      <header className="text-center text-2xl font-bold mb-6 text-gray-900">
        My <span className="text-secondaryColor">Vitals</span>
      </header>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {/* Systolic Blood Pressure */}
        <GaugeCard
          title="Systolic Blood Pressure"
          value={parseFloat(vitalsData.blood_pressure.split("/")[0])}
          minValue={0}
          maxValue={160}
          ranges={systolicBPRanges}
          unit="mm Hg"
          status={vitalsData.blood_pressure_status}
          healthyRange="80-130 mm Hg"
        />

        {/* Diastolic Blood Pressure */}
        <GaugeCard
          title="Diastolic Blood Pressure"
          value={parseFloat(vitalsData.blood_pressure.split("/")[1])}
          minValue={0}
          maxValue={100}
          ranges={diastolicBPRanges}
          unit="mm Hg"
          status={vitalsData.blood_pressure_status}
          healthyRange="50-90 mm Hg"
        />

        {/* Heart Rate */}
        <GaugeCard
          title="Heart Rate"
          value={parseFloat(vitalsData.predicted_heart_rate)}
          minValue={0}
          maxValue={120}
          ranges={heartRateRanges}
          unit="bpm"
          healthyRange="60-100 bpm"
        />

        {/* Breathing Rate */}
        <GaugeCard
          title="Breathing Rate"
          value={parseFloat(vitalsData.breathing_rate)}
          minValue={0}
          maxValue={30}
          ranges={breathingRateRanges}
          unit="breaths/min"
          status={vitalsData.breathing_rate_status}
          healthyRange="12-20 breaths/min"
        />

        {/* Heart Rate Variability */}
        <GaugeCard
          title="Heart Rate Variability"
          value={parseFloat(vitalsData.heart_rate_variability)}
          minValue={0}
          maxValue={160}
          ranges={hrvRanges}
          unit="ms"
          status={vitalsData.heart_rate_variability_status}
          healthyRange="50-110 ms"
        />

        {/* BMI */}
        <GaugeCard
          title="BMI"
          value={parseFloat(vitalsData.bmi_score)}
          minValue={0}
          maxValue={40}
          ranges={bmiRanges}
          status={vitalsData.bmi_status}
          healthyRange="18.5-25"
        />

        {/* Oxygen Level */}
        <GaugeCard
          title="Oxygen Level"
          value={vitalsData.oxygen_level}
          minValue={90}
          maxValue={100}
          ranges={oxygenLevelRanges}
          unit="SpO2 (%)"
          status={vitalsData.oxygen_level < 95 ? "Low" : "Normal"}
          healthyRange="90-100%"
        />

<GaugeCard
  title="Stress Index"
  value={parseFloat(vitalsData.curebay_stress_index)}
  minValue={0}
  maxValue={6}
  ranges={stressIndexRanges}
  status={
    Math.round((parseFloat(vitalsData.curebay_stress_index) / 6) * 100) <= 50
      ? "Normal"
      : Math.round((parseFloat(vitalsData.curebay_stress_index) / 6) * 100) <= 75
      ? "Elevated"
      : "High Stress"
  }
  healthyRange="Less than 50%"
/>


      </div>
      <button
        type="button"
        className="relative align-middle bg-primaryColor text-White px-6 py-3 rounded-md font-semibold hover:bg-secondaryColor transition mt-10"
        onClick={() => {
          window.history.back();
        }}
      >
        Back to Home
      </button>
    </div>
  );
};

export default SmartVitalsReport;
