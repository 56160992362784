import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
// import { USERPROFILE_ROUTES } from '../application/Router/constants/UserProfileRoutes';
import Layouts from "./../../Layouts";
import Tickmark from '../../../Assets/Images/tickmarkgreen.png';
import { trackPurchase } from '../../utils/analytics/events';

function CartOrderSucess() {
  const history = useHistory();
  const location = useLocation();
  const { state } = location;
  const link = state?.link
  console.log(state,"gtgdbhhnfnhd")
  useEffect(() => {
    sessionStorage.removeItem("paymentRemarks");
    window.dataLayer.push({ ecommerce: null });
    if (state?.cartData?.patientMedicineOrder?.length && state?.cartData?.patientLabTestsOrder?.length) {
      trackPurchase("purchase_medicine_labtest" , [
        ...state.cartData.patientMedicineOrder[0]
          .patientMedicineOrderDetailsList,
        ...state.cartData.patientLabTestsOrder[0]
          .patientLabTestsOrderDetailsList,
      ] , state.amount)
    } else if (state?.cartData?.patientMedicineOrder?.length) {
      trackPurchase("purchase_medicine" , [...state.cartData.patientMedicineOrder[0].patientMedicineOrderDetailsList] , state.amount)
    } else if (state?.cartData?.patientLabTestsOrder?.length) {
      trackPurchase("purchase_labtest" , [...state.cartData.patientLabTestsOrder[0].patientLabTestsOrderDetailsList] , state.amount)
    }
  }, [])
  const redirectTo = (event) => {
    event.preventDefault();
   
    if (state?.cartData?.patientMedicineOrder?.length > 0) {
      history.push("/medicineorder")
     
    } else if (state?.cartData?.patientLabTestsOrder?.length > 0) {
      history.push("/laborder")
     
    }else {
      
      history.push("/")
    }
    if (/Mobi|Android/i.test(navigator.userAgent)) {
      window.scrollTo(0, 0);
    }else {
      window.scrollTo(0, 0);
    }
  };
  console.log(state, "dsivhsdovous");
  return (
    <Layouts>
      <section className="bg-lightGray md:py-8 py-6 md:px-28">
        <div className="m-8 md:m-20">

          <div className="flex flex-col justify-center items-center ">
          <img
                  src={Tickmark}
                  className="h-14 w-14"
                  alt="#"
                />
            <h2 className="text-green-500 text-xl font-normal text-center  mb-8 mt-3">Thank You. Your order is placed successfully. Your Transaction ID is {state?.txnid}.</h2>
          </div>
          <div className="flex text-center justify-center ">
          </div>
          <div className="flex text-center justify-center ">
            <button
              onClick={redirectTo}
              className={`mt-3 mb-6 py-2 px-4 text-White disabled:cursor-default rounded transition ease-in duration-200 text-center text-sm font-semibold focus:outline-none`}
              style={{ background: "#66B889" }}
            >
              {" "}
              {"Continue Shopping"}
            </button>
          </div>
          <div className="flex mb-8 justify-center items-center">
            {/* <button onClick={(e) => redirectTo(e, link == "PATIENTMEDICINEORDERS" ? USERPROFILE_ROUTES.PATIENTMEDICINEORDERS: USERPROFILE_ROUTES.MY_ORDERS)} className="bg-brand-primary text-white p-2 rounded-xl text-center"><a href="#" className="text-lg md:text-xl mx-3 md:m-8 font-medium text-white-500">Go to Home</a></button> */}
            {/* <button onClick={(e) => { redirectTo(e, APP_ROUTES.ORDER_DETAILS) }} className="border border-brand-primary text-sm text-brand-primary rounded-xl p-2 mr-2"><a href="#" className="text-xl m-8 font-medium text-indigo-500">Track Order</a></button> */}
          </div>
        </div>
      </section>
    </Layouts>

  );
}
export default CartOrderSucess;