import React, {useState} from 'react'
import HIW1 from "../../../Assets/Images/smartVitals/how-it-works-1.png";
import HIW2 from "../../../Assets/Images/smartVitals/how-it-works-2.png";
import HIW3 from "../../../Assets/Images/smartVitals/how-it-works-3.png";
import HIW4 from "../../../Assets/Images/smartVitals/how-it-works-4.png";
import SubHeader from "../../Shared/SearchBar";
import Layouts from "../../Layouts/index";
import TrustIcon1 from "../../../Assets/Images/Icons/TrustIcon1.svg";
import Guid1 from "../../../Assets/Images/cameraPlacement.svg";
import Guid2 from "../../../Assets/Images/lightning.svg";
import Guid3 from "../../../Assets/Images/minimalMovement.svg";
import { useApiData } from '../../../Context/ApidtaContext';

import { Modal } from 'antd';
// import Smartvitalsvid from "../../../Assets/videos/Smart vitals.mp4";
const SmartVitalsDetails = () => {
  const {caresathi}  = useApiData();
  console.log(caresathi,"caresathicms")

  
  const ContactlessMonitoring = caresathi?.attributes?.forthComp?.[1]?.image_url?.[0]?.image_url;

  const RealTimeAccuracy = caresathi?.attributes?.forthComp?.[1]?.image_url?.[1]?.image_url;
  ;
  const EmpatheticInsights = caresathi?.attributes?.forthComp?.[1]?.image_url?.[2]?.image_url;
  ;
  const PeaceOfMind = caresathi?.attributes?.forthComp?.[1]?.image_url?.[3]?.image_url;

  const aitool = caresathi?.attributes?.thirdComp?.[0]?.image_url;
  const labinsight = caresathi?.attributes?.thirdComp?.[2]?.image_url;
  const caresathim = caresathi?.attributes?.headerComp?.[0]?.image_url;
  const dentalcare = caresathi?.attributes?.thirdComp?.[3]?.image_url;


  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };
  return (
    <Layouts style={{ backgroundColor: "#004171" }}>
      {/* Title Content */}
      {/* <SubHeader /> */}
      <section className="home-banner min-h-[70vh] my-8  ">
        <div className="container mx-auto">
          <div className="bg-primaryColor absolute left-0 md:w-[90%] h-[70vh] rounded-e-xl"></div>
          <div className="md:grid grid-cols-2 gap-5 min-h-[70vh] items-center relative">
            <div className="relative z-1 pt-7 md:pt-5 p-5 md:p-0 rounded-2xl bg-[#F3F8FF] md:bg-primaryColor">
              <span className="text-secondaryColor text-sm md:text-6xl font-medium  mb-5 md:mb-3">
                SmartVitals
              </span>
              <h1 className="text-primaryColor md:text-White text-2xl md:text-3xl font-bold sm:mt-8 mt-0 ">
                A Caring Touch
              </h1>
              <span className="text-secondaryColor text-sm md:text-3xl font-medium  mb-1 md:mb-3">
                Without The Touch
              </span>

              <p className="text-primaryColor md:text-White font-extralight text-sm md:text-base mt-2 mb-4">
                SmartVitals offers instant, contactless monitoring of your heart
                rate, oxygen levels, and more, providing real-time insights and
                peace of mind. It quietly watches over you, offering reassurance
                and control without physical touch. With AI-driven technology,
                SmartVitals ensures you stay connected to your well-being,
                reducing worry and enhancing comfort.
              </p>
              <a
                className=" rounded-lg px-4 py-2 mr-2 mt-4 bg-secondaryColor"
                style={{ color: "#ffff" }}
                href="/smart-vital-instr"
              >
                Check My Vitals Now
              </a>
              {/* <button
                className=" rounded-lg px-4 py-2 mr-2 mt-4 bg-secondaryColor cursor"
                style={{ color: "#ffff" }}
                // href="ask-care-sathi?lab_report_analyser=true&popup=true"
                onClick={() => showModal()}
              >
                Check My Vitals Now
              </button> */}
            </div>
            {/* <div className="relative z-1 flex justify-end mt-5 md:mt-0">
                              <div className="w-full md:w-[90%] h-[300px] md:h-[350px] rounded banner-bg"></div>
                          </div> */}
             <div className="hidden sm:block z-1 absolute right-24 top-12">
             <video
                      className="w-full h-[500px] rounded-[20px]"
                      autoPlay
                      loop
                      muted
                      preload="auto"
                      playsInline
 
                    >
                      <source
                        src={`${process.env.REACT_APP_IMG_BASEURL}Smart%20vitals%20up%202.mp4`}
                        type="video/mp4"
                      />
                    </video>
            </div>
            <div className="flex md:hidden justify-center items-center gap-3 mt-5">
              <img src={TrustIcon1} alt="images" width={25} />
              <p className="text-primaryColor font-extralight text-sm">
                Trusted by{" "}
                <span className="font-medium">Over 100,000 Patients</span>
              </p>
            </div>
          </div>
          {/* <div className="swipicon">
                          <img src={Swipicon} alt="images" />
                      </div> */}
        </div>
      </section>
      <section className="">
        <div className="container mx-auto py[50px] md:py-[90px]">
          <h2 className="text-center text-2xl md:text-4xl font-semibold text-primaryColor leading-normal mb-8 md:mb-[50px]">
            How it <span className="text-secondaryColor">Works</span>?
          </h2>
          <div className="px-[14px] pb-6 sm:pb-auto sm:px-auto md:w-[80%] mx-auto flex sm:flex-row flex-col justify-between items-start gap-10">
            {/* Step 1 */}
            <div className="gap-3 sm:gap-auto flex sm:flex-col flex-row text-left">
              <div className="bg-[#F0F8FF] rounded-full w-20 sm:h-20 h-14 flex items-center justify-center mb-4">
                <img src={HIW1} alt="Step 1" className="w-16 sm:h-16 h-10" />
              </div>
              <div className="flex flex-col">
                <div
                  className="sm:text-base text-sm font-semibold text-left"
                  style={{ color: "#42BA85" }}
                >
                  STEP 1
                </div>
                <div
                  className="text-base font-semibold text-left"
                  style={{ color: "#004171" }}
                >
                  Effortless Setup
                </div>
                <p
                  className="sm:text-sm text-[13px] font-medium"
                  style={{ color: "#676C80" }}
                >
                 Scan your fingertip using your phone or webcam—no contact needed
                </p>
              </div>
            </div>

            {/* Step 2 */}
            <div className="flex gap-3 sm:gap-auto  sm:flex-col flex-row">
              <div className="bg-[#F0F8FF] rounded-full sm:w-20 w-24 sm:h-20 h-14 flex items-center justify-center mb-4">
                <img src={HIW2} alt="Step 2" className="w-16 sm:h-16 h-10" />
              </div>
              <div className="flex flex-col">
                <div
                  className="sm:text-base text-sm font-semibold"
                  style={{ color: "#42BA85" }}
                >
                  STEP 2
                </div>
                <div
                  className="text-base font-semibold"
                  style={{ color: "#004171" }}
                >
                 Instant Insights
                </div>
                <p
                  className="sm:text-sm text-[13px] font-medium"
                  style={{ color: "#676C80" }}
                >
                 Get immediate, accurate readings of heart rate, oxygen levels, and more.
                </p>
              </div>
            </div>

            {/* Step 3 */}
            <div className="flex gap-3 sm:gap-auto  sm:flex-col flex-row">
              <div className="bg-[#F0F8FF] rounded-full w-20 sm:h-20 h-14 flex items-center justify-center mb-4">
                <img src={HIW3} alt="Step 3" className="w-16 sm:h-16 h-10" />
              </div>
              <div className="flex flex-col">
                <div
                  className="sm:text-base text-sm font-semibold"
                  style={{ color: "#42BA85" }}
                >
                  STEP 3
                </div>
                <div
                  className="text-base font-semibold"
                  style={{ color: "#004171" }}
                >
                  Personalised Monitoring
                </div>
                <p
                  className="sm:text-sm text-[13px] font-medium"
                  style={{ color: "#676C80" }}
                >
                 Tailored health insights and alerts keep you informed.
                </p>
              </div>
            </div>

            {/* Step 4 */}
            <div className="flex gap-3 sm:gap-auto  sm:flex-col flex-row ">
              <div className="bg-[#F0F8FF] rounded-full w-20 sm:h-20 h-14 flex items-center justify-center mb-4">
                <img src={HIW4} alt="Step 4" className="w-16 sm:h-16 h-10" />
              </div>
              <div className="flex flex-col">
                <div
                  className="sm:text-base text-sm font-semibold"
                  style={{ color: "#42BA85" }}
                >
                  STEP 4
                </div>
                <div
                  className="text-base font-semibold"
                  style={{ color: "#004171" }}
                >
                  Continuous Care
                </div>
                <p
                  className="sm:text-sm text-[13px] font-medium"
                  style={{ color: "#676C80" }}
                >
                  Access your health data anytime, ensuring peace of mind wherever you are.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-[#f3f8ff]">
        <div className="container mx-auto py[50px] md:py-[90px] px-4">
          <h2 className="text-center pt-5 sm:pt-auto text-2xl md:text-4xl font-semibold text-primaryColor leading-normal mb-6 md:mb-[50px]">
            What You Can <span className="text-secondaryColor">Expect</span>
          </h2>
          <div className="flex justify-center items-center min-h-screen">
            <div className="grid sm:grid-cols-2 grid-cols-1 sm:gap-8 gap-0">
              <div className="flex flex-col items-center">
                <img src={ContactlessMonitoring} alt="Touch-Free Monitoring" />
                <div
                  style={{ backgroundColor: "#ffff" }}
                  className="sm:w-[68%] w-[90%] rounded-xl relative bottom-8 left-0 px-4 py-2"
                >
                  <p
                    style={{ color: "#004171" }}
                    className="sm:text-xl text-base font-semibold"
                  >
                    Contactless Monitoring
                  </p>
                  <p
                    style={{ color: "#676C80" }}
                    className="sm:text-base text-sm font-medium"
                  >
                    Seamless, hassle-free health checks without physical
                    contact.
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center">
                <img src={RealTimeAccuracy} alt="Quick & Accurate Readings" />
                <div
                  style={{ backgroundColor: "#ffff" }}
                  className="sm:w-[68%] w-[90%] rounded-xl relative bottom-8 left-0 px-4 py-2"
                >
                  <p
                    style={{ color: "#004171" }}
                    className="sm:text-xl text-base font-semibold"
                  >
                    Real-Time Accuracy
                  </p>
                  <p
                    style={{ color: "#676C80" }}
                    className="sm:text-base text-sm font-medium"
                  >
                    Instant, precise readings for informed health - decisions
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center">
                <img src={EmpatheticInsights} alt="Convenient Setup" />
                <div
                  style={{ backgroundColor: "#ffff" }}
                  className="sm:w-[68%] w-[90%] rounded-xl relative bottom-8 left-0 px-4 py-2"
                >
                  <p
                    style={{ color: "#004171" }}
                    className="sm:text-xl text-base font-semibold"
                  >
                    Empathetic Insights
                  </p>
                  <p
                    style={{ color: "#676C80" }}
                    className="sm:text-base text-sm font-medium"
                  >
                    Advanced technology paired with a caring approach for
                    personalized support.
                  </p>
                </div>
              </div>
              <div className="flex flex-col items-center">
                <img src={PeaceOfMind} alt="Peace Of Mind" />
                <div
                  style={{ backgroundColor: "#ffff" }}
                  className="sm:w-[68%] w-[90%] rounded-xl relative bottom-8 left-0 px-4 py-2"
                >
                  <p
                    style={{ color: "#004171" }}
                    className="sm:text-xl text-base font-semibold"
                  >
                    Peace Of Mind
                  </p>
                  <p
                    style={{ color: "#676C80" }}
                    className="sm:text-base text-sm font-medium"
                  >
                    SmartVitals gives you constant access to your health data,
                    letting you live confidently and with ease wherever you are.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-White mb-4">
        <div className=" py-10 px-4">
          <h2 className="text-center text-2xl md:text-4xl font-semibold text-primaryColor mb-8">
            Set Up Guidelines for{" "}
            <span className="text-secondaryColor">Best Results</span>
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 sm:mt-14 mt-2">
            {/* Camera Placement Card */}
            <div className="text-center">
              <img
                src={Guid1}
                alt="Camera Placement"
                className="mx-auto w-full max-w-36"
              />
              <h3 className="text-lg font-bold text-[#004171] mt-4">
                Camera Placement
              </h3>
              <p className="text-gray-600 mt-2">
              Ensure your camera is stable and focused on your index fingertip
              </p>
            </div>

            {/* Lighting Card */}
            <div className="text-center">
              <img
                src={Guid2}
                alt="Lighting"
                className="mx-auto w-full max-w-36"
              />
              <h3 className="text-lg font-bold text-[#004171] mt-4">
                Lighting
              </h3>
              <p className="text-gray-600 mt-2">
              Use natural light or bright lighting for optimal results
              </p>
            </div>

            {/* Minimal Movement Card */}
            <div className="text-center">
              <img
                src={Guid3}
                alt="Minimal Movement"
                className="mx-auto w-full max-w-36"
              />
              <h3 className="text-lg font-bold text-[#004171] mt-4">
                Minimal Movement
              </h3>
              <p className="text-gray-600 mt-2">
              Keep your hand steady during the 30-second recording
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-lightGray mb-4">
        <div className="bg-[#023b72] py-12 flex flex-col justify-center items-center min-h-screen">
          <div className=" block sm:hidden text-center  z-10">
            <h2 className="text-3xl font-bold mb-2 text-White">
              Check Our Other
            </h2>
            <h3 className="text-3xl font-bold text-[#42BA85] mb-6">AI Tools</h3>
          </div>
          {/* Main Container */}

          <div className=" w-full max-w-5xl rounded-2xl flex flex-col  items-start justify-between relative overflow-hidden">
            {/* Left Section with Heading */}
            <div className="hidden sm:flex w-full  max-w-5xl bg-[#0a5a9c]  rounded-t-2xl  items-start justify-between relative overflow-hidden">
              <div className="text-white z-10 md:w-1/2 sm:pl-[50px] sm:pt-[100px]">
                <h2 className="text-3xl font-bold mb-2 text-White">
                  Check Our Other
                </h2>
                <h3 className="text-3xl font-bold text-[#42BA85] mb-6">
                  AI Tools
                </h3>
              </div>

              {/* Background Image */}
              <img
                src={aitool}
                alt="Doctor"
                className="  md:w-[66%] w-full object-cover rounded-t-2xl"
                style={{ zIndex: 1, maxHeight: "100%" }}
              />
            </div>

            {/* AI Tools Cards Section */}
            <div className=" rounded-b-xl bg-[#0a5a9c] grid grid-cols-1 md:grid-cols-3 gap-6 z-10 mt-8 md:mt-0 px-[50px] py-[45px]">
              {/* Card 1 */}
              <div className="bg-white rounded-xl overflow-hidden ">
                <img
                  src={labinsight}
                  alt="Labinsight"
                  className="w-full object-cover"
                />
                <div
                  className="p-4 bg-White rounded-b-xl "
                  style={{ height: "132px" }}
                >
                  <h4 className="text-lg font-semibold text-[#004171]">
                    LabInsights
                  </h4>
                  <p className="text-sm text-[#676C80]">
                    From uncertainty to clarity, we are with you
                  </p>
                  <a
                    href="/lab-insight"
                    className="text-[#42BA85] text-sm font-medium mt-2 inline-block"
                  >
                    Know More &rarr;
                  </a>
                </div>
              </div>

              {/* Card 2 */}
              <div className="bg-white rounded-xl overflow-hidden">
                <img
                  src={caresathim}
                  alt="Smart Vitals"
                  className="w-full  object-cover"
                />
                <div className="p-4  bg-White rounded-b-xl ">
                  <h4 className="text-lg font-semibold text-[#004171]">
                    CareSathi
                  </h4>
                  <p className="text-sm text-[#676C80]">
                    Get insights from your lab reports with AI precision
                  </p>
                  <a
                    href="/care-sathi"
                    className="text-[#42BA85] text-sm font-medium mt-2 inline-block"
                  >
                    Know More &rarr;
                  </a>
                </div>
              </div>

              {/* Card 3 */}

              <div className="bg-white rounded-xl overflow-hidden">
                <img
                  src={dentalcare}
                  alt="AI Powered Dentistry"
                  className="w-full  object-cover"
                />
                <div
                  className="p-4  bg-White rounded-b-xl"
                  style={{ height: "128px" }}
                >
                  <h4 className="text-lg font-semibold text-[#004171]">
                    DentalCare
                  </h4>
                  <p className="text-sm text-[#676C80]">
                    Transform your smile with technology
                  </p>
                  <a
                    href="/services/dentalcare"
                    className="text-[#42BA85] text-sm font-medium mt-2 inline-block"
                  >
                    Know More &rarr;
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal 
        title={<h2 style={{ fontSize: '24px', fontWeight: 'bold', textAlign: 'center' }}>Coming Soon</h2>}
        open={isModalVisible} 
        onOk={handleOk} 
        okText="Ok"
        onCancel={handleOk}
        centered
      >
        <p className='mt-4 text-md' >Thank you for being interested in what we do!</p>
      </Modal>
    </Layouts>
  );
};

export default SmartVitalsDetails;