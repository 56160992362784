import React, { useState } from 'react';
import SmartVitalCheckerHeroIcon from '../../../Assets/Images/smartVitals/smart-vitals-checker-hero-icon.png';
import { Form, Input, Select, Button, Row, Col, Typography } from 'antd';
import Header from "../../Shared/Header";

const { Text } = Typography;
const { Option } = Select;

const FormComponent = ({ onFinish }) => {
  const [form] = Form.useForm();
  const [heightUnit, setHeightUnit] = useState('cm');
  const [weightUnit, setWeightUnit] = useState('kg');

  const handleFinish = (values) => {
    onFinish({ ...values, heightUnit, weightUnit });
  };

  return (
    <>
      <Header />
      <div className="flex flex-col">
        <div className="bg-primaryColor text-white py-4 rounded-lg shadow-md w-full mt-24 px-4">
          <p className="font-semibold text-lg ml-3 text-White">
            Create Facial Key:
            <span className="font-normal ml-2 text-base text-White">
              Create a map of your face. You can delete the map at any time.
            </span>
          </p>
        </div>
        <div className="min-h-screen flex flex-col md:flex-row items-center gap-6 px-4 py-6 md:px-12 md:py-8">
          <div className="relative w-full md:w-1/2 lg:w-1/2 mb-6">
            <img
              src={SmartVitalCheckerHeroIcon}
              className="w-full rounded-lg object-cover h-[25rem] sm:h-[30rem] lg:h-[35rem]"
              alt="Smart Vitals Checker"
            />
            <div className="absolute inset-0 flex flex-col justify-center items-center text-center text-white">
              <Text className="text-3xl font-bold md:text-4xl text-White">SmartVitals</Text>
              <Text className="text-lg md:text-xl mt-2 text-White">Monitor Your Health Safely And Easily</Text>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-lg p-6 md:p-8 w-full md:w-1/2 lg:w-1/2">
            <Form
              form={form}
              name="healthForm"
              layout="vertical"
              onFinish={handleFinish}
              requiredMark={false}
            >
              <Row gutter={16}>
               
                <Col xs={24} className="mb-4">
                  <Form.Item
                    label={<span className="text-lg">Height <span className="text-danger">*</span></span>}
                    name="height"
                    rules={[
                      { required: true, message: 'Please enter your height' },
                      { pattern: /^[0-9]+$/, message: 'Only whole numbers are allowed' },
                    
                    ]}
                  >
                    <Input
                      size="large"
                      type="number"
                      placeholder="Enter height"
                      addonAfter={<span className="text-gray-500">{heightUnit}</span>}
                      step="1"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} className="mb-4">
                  <Form.Item
                    label={<span className="text-lg">Weight <span className="text-danger">*</span></span>}
                    name="weight"
                    rules={[
                      { required: true, message: 'Please enter your weight' },
                      { pattern: /^[0-9]+$/, message: 'Only whole numbers are allowed' },
                    
                    ]}
                  >
                    <Input
                      size="large"
                      type="number"
                      placeholder="Enter weight"
                      addonAfter={
                        <Select defaultValue="kg" onChange={value => setWeightUnit(value)}>
                          <Option value="kg">kg</Option>
                          <Option value="lbs">lbs</Option>
                        </Select>
                      }
                      step="1"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} className="mb-4">
                  <Form.Item
                    label={<span className="text-lg">Diabetes <span className="text-danger">*</span></span>}
                    name="diabetes"
                    rules={[{ required: true, message: 'Please select an option' }]}
                  >
                    <Select size="large" placeholder="Select">
                      <Option value="true">Yes</Option>
                      <Option value="false">No</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} className="mb-4">
                  <Form.Item
                    label={<span className="text-lg">Smoke <span className="text-danger">*</span></span>}
                    name="smoke"
                    rules={[{ required: true, message: 'Please select an option' }]}
                  >
                    <Select size="large" placeholder="Select">
                      <Option value="true">Yes</Option>
                      <Option value="false">No</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Button type="primary" htmlType="submit" className="w-full bg-blue-900 hover:bg-blue-800 text-white text-lg">
                  Next
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormComponent;
