const ConciergeServices = ({data}) =>{

    const splitHeading = (sentence) => {
        // Trim sentence and extract words
        const trimmedSentence = sentence.trim();
        const words = trimmedSentence.split(' ');
      
        // Get the last word and the rest of the sentence
        const lastWord = words.pop(); // Removes the last word from the array
        const restOfSentence = words.join(' '); // Joins the remaining words
      
        return (
          <p className="text-center mb-2" >
            <span className="text-xl md:text-4xl font-bold" style={{ color: '#004171' }}>{restOfSentence}</span>{' '}
            <span className="text-xl md:text-4xl font-bold" style={{ color: '#42BA85' }}>{lastWord}</span>
          </p>
        );
      };

    return(
    <section id = "allservice" className="flex items-center flex-col my-4 md:my-10" >
    {splitHeading(data?.thirdComp[0].heading)}
    <p className="text-center hidden md:block" >{data?.thirdComp[0].subTitle}</p>
    <img className="hidden md:block text-center my-4 md:my-8"  src = {data?.thirdComp[0].image_url} />
    <img className="block md:hidden text-center my-4 md:my-8"  src = {data?.thirdComp[0].image_url_mob} />
    </section>)
}

export default ConciergeServices