import React, { useState, useEffect, useRef } from "react";
import Layouts from "../../Layouts";
import PersonalizedAssistant from "../../../Assets/Images/Icons/PersonalizedAssistant.svg";
import EmotionalSupport from "../../../Assets/Images/Icons/EmotionalSupport.svg";
import Continuous from "../../../Assets/Images/Icons/Continuous.svg";
import Comperhensive from "../../../Assets/Images/Icons/Comperhensive.svg";
import PieceMind from "../../../Assets/Images/Icons/PieceMind.svg";
import SubHeader from "../../Shared/SearchBar";
import dentalcareimg1 from "../../../Assets/Images/dentalcareimg1.png";
import dentalcareimg2 from "../../../Assets/Images/dentalcareimg2.png";
import dentalcareimg3 from "../../../Assets/Images/dentalcareimg3.png";
import dentalcareimg4 from "../../../Assets/Images/dentalcareimg4.png";
import { Collapse } from "antd";
import Arrowimage from "../../../Assets/Images/Icons/linearrow.png";
import http from "../../../Redux/Services/http-common";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { checkIsLoggedIn } from "../../../Redux/Action/userActions";
import { LoadingOutlined } from "@ant-design/icons";
import { Modal } from "antd";

import sucessvectoricon from "../../../Assets/Images/sucessvectoricon.svg";

import { Spin } from "antd";
const { Panel } = Collapse;

const faqData = {
  faq: [
    {
      question: "Crowding of teeth",
      answer:
        "Crowding occurs when there’s insufficient space in the jaw for all teeth, causing them to overlap or misalign. It can result from genetics, early or late loss of baby teeth, or habits like thumb-sucking. Treatment typically involves orthodontics, such as braces or aligners, to realign teeth and improve oral hygiene, appearance, and function.",
    },
    {
      question: "Open bite",
      answer:
        "Crowding occurs when there’s insufficient space in the jaw for all teeth, causing them to overlap or misalign. It can result from genetics, early or late loss of baby teeth, or habits like thumb-sucking. Treatment typically involves orthodontics, such as braces or aligners, to realign teeth and improve oral hygiene, appearance, and function.",
    },
    {
      question: "Protruded teeth",
      answer:
        "Crowding occurs when there’s insufficient space in the jaw for all teeth, causing them to overlap or misalign. It can result from genetics, early or late loss of baby teeth, or habits like thumb-sucking. Treatment typically involves orthodontics, such as braces or aligners, to realign teeth and improve oral hygiene, appearance, and function.",
    },
    {
      question: "Cross bite",
      answer:
        "Crowding occurs when there’s insufficient space in the jaw for all teeth, causing them to overlap or misalign. It can result from genetics, early or late loss of baby teeth, or habits like thumb-sucking. Treatment typically involves orthodontics, such as braces or aligners, to realign teeth and improve oral hygiene, appearance, and function.",
    },
    {
      question: "Under bite",
      answer:
        "Crowding occurs when there’s insufficient space in the jaw for all teeth, causing them to overlap or misalign. It can result from genetics, early or late loss of baby teeth, or habits like thumb-sucking. Treatment typically involves orthodontics, such as braces or aligners, to realign teeth and improve oral hygiene, appearance, and function.",
    },
    {
      question: "Missing teeth",
      answer:
        "Crowding occurs when there’s insufficient space in the jaw for all teeth, causing them to overlap or misalign. It can result from genetics, early or late loss of baby teeth, or habits like thumb-sucking. Treatment typically involves orthodontics, such as braces or aligners, to realign teeth and improve oral hygiene, appearance, and function.",
    },
    {
      question: "Deep bite teeth",
      answer:
        "Crowding occurs when there’s insufficient space in the jaw for all teeth, causing them to overlap or misalign. It can result from genetics, early or late loss of baby teeth, or habits like thumb-sucking. Treatment typically involves orthodontics, such as braces or aligners, to realign teeth and improve oral hygiene, appearance, and function.",
    },
  ],
};

const CustomPanelHeader = ({ headerText }) => {
  return (
    <div className="flex gap-4 items-center">
      {/* <img src={Arrowimage} alt="images" className="h-[20px]" /> */}
      <span className="text-[#373737] sm:text-2xl text-lg font-medium">
        {headerText}
      </span>
    </div>
  );
};
const encodeBase64File = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

function Dentalcare() {
  const hiddenFileInput1 = useRef(null);
  const hiddenFileInput2 = useRef(null);
  const hiddenFileInput3 = useRef(null);
  const userData = useSelector((state) => state.authReducer.patientData);
  console.log(userData, "iuhklnlk");
  const [error, setError] = useState(null);
  const [b64File1, setB64File1] = useState("");
  const [file1, setFile1] = useState("");
  const [b64File2, setB64File2] = useState("");
  const [file2, setFile2] = useState("");
  const [b64File3, setB64File3] = useState("");
  const [file3, setFile3] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const [filetype1, setFiletype1] = useState("");
  const [filetype2, setFiletype2] = useState("");
  const [filetype3, setFiletype3] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [timer, setTimer] = useState(10);
  const [reportUrl, setReportUrl] = useState("");
  const history = useHistory();
  const location = useLocation();
  const search = useLocation().search;
  const search_query = new URLSearchParams(search).get("source");
  let profileData = localStorage.getItem("patientInfo");
  const storedData = JSON.parse(localStorage.getItem("patientInfo"));
  console.log(storedData?.data, "ProfileDataaa");
  const changeHandler1 = async (e) => {
    const file = e.target.files[0]; // Only allow one file
    if (!file) return;

    const fileType = file.type.split("/")[1].toUpperCase();
    setFiletype1(fileType);
    const validFileTypes = ["JPG", "PNG", "JPEG"];

    if (!validFileTypes.includes(fileType)) {
      setError("Please upload JPEG, JPG, PNG formats only");
      setB64File1(""); // Clear the previous image if error occurs
      return;
    }

    const fileSizeMB = file.size / 1000000;
    if (fileSizeMB > 5) {
      setError("Please upload a photo smaller than 5MB");
      setB64File1(""); // Clear the previous image if error occurs
      return;
    }

    const base64File = await encodeBase64File(file);
    setFile1(base64File);
    setB64File1(base64File.split(",")[1]);

    setError(null);
  };

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const changeHandler2 = async (e) => {
    const file = e.target.files[0]; // Only allow one file
    if (!file) return;

    const fileType = file.type.split("/")[1].toUpperCase();
    setFiletype2(fileType);
    const validFileTypes = ["JPG", "PNG", "JPEG"];

    if (!validFileTypes.includes(fileType)) {
      setError("Please upload JPEG, JPG, PNG formats only");
      setB64File2(""); // Clear the previous image if error occurs
      return;
    }

    const fileSizeMB = file.size / 1000000;
    if (fileSizeMB > 5) {
      setError("Please upload a photo smaller than 5MB");
      setB64File2(""); // Clear the previous image if error occurs
      return;
    }

    const base64File = await encodeBase64File(file);
    setFile2(base64File);
    setB64File2(base64File.split(",")[1]);

    setError(null);
  };

  const changeHandler3 = async (e) => {
    const file = e.target.files[0]; // Only allow one file
    if (!file) return;

    const fileType = file.type.split("/")[1].toUpperCase();
    const validFileTypes = ["JPG", "PNG", "JPEG"];
    setFiletype3(fileType);
    if (!validFileTypes.includes(fileType)) {
      setError("Please upload JPEG, JPG, PNG formats only");
      setB64File3(""); // Clear the previous image if error occurs
      return;
    }

    const fileSizeMB = file.size / 1000000;
    if (fileSizeMB > 5) {
      setError("Please upload a photo smaller than 5MB");
      setB64File3(""); // Clear the previous image if error occurs
      return;
    }

    const base64File = await encodeBase64File(file);
    setFile3(base64File);
    setB64File3(base64File.split(",")[1]);

    setError(null);
  };

  const clicktype1 = () => {
    hiddenFileInput1.current.click();
  };

  const clicktype2 = () => {
    hiddenFileInput2.current.click();
  };
  const clicktype3 = () => {
    hiddenFileInput3.current.click();
  };

  console.log(process.env.REACT_APP_NEWBASEURL, process.env, "environ");
  console.log(userData, "UserData");

  const startTimer = () => {
    let countdown = 15;
    const interval = setInterval(() => {
      countdown -= 1;
      setTimer(countdown);

      if (countdown === 0) {
        clearInterval(interval);
      }
    }, 1000);
  };

  const handleFormSubmit = async () => {
    try {
      // Assuming userData, b64File1, b64File2, b64File3 validations are already done
      setButtonLoader(true);

      const payload = {
        patient_id: userData.code,
        front_view: b64File1,
        upper_view: b64File2,
        lower_view: b64File3,
        gender: storedData?.data?.gender,
        mobile_number: storedData?.data?.mobile,
        date: getFormattedDate(),
        age: calculateAge(storedData?.data?.dob),
        name: `${storedData?.data?.firstName} ${storedData?.data?.lastName}`,
      };

      // First API call
      const response = await fetch(
        `https://dentalcare-stage.curebay.in/process-image/`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(payload),
        }
      );

      setButtonLoader(false);

      if (response.status === 200) {
        const responseData = await response.json();
        console.log(responseData);
        toast("Please wait while we generate your Dental Report");
        // Second API call
        const reportResponse = await fetch(
          `https://common-stage.curebay.in/cs/dentalReport/generate`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(payload),
          }
        );

        if (reportResponse.status === 200) {
          const reportData = await reportResponse.json();
          console.log(reportData, "Report Response");

          // Show the success modal with the report URL

          setReportUrl(reportData.url);

          setIsModalVisible(true);
          startTimer();
        } else {
          toast("Report generation failed. Please try again.");
        }
      } else {
        toast("There was an issue with your submission. Please try again.");
      }
    } catch (e) {
      console.error(e);
      toast("An error occurred. Please try again.");
      setButtonLoader(false);
    }
  };

  const getFormattedDate = () => {
    const now = new Date();
    return now.toISOString(); // Generates date in the 'YYYY-MM-DDTHH:mm:ss.SSSZ' format
  };

  if (search_query == "qrcode" && !checkIsLoggedIn()) {
    history.push({
      pathname: "/",
      state: { from: location.pathname, isLoginModalOpen: true },
    });
    return;
  }
  return (
    <Layouts>
      <SubHeader />
      <ToastContainer />
      <Modal
        visible={isModalVisible}
        footer={null}
        onCancel={() => setIsModalVisible(false)}
        centered
      >
        <div className="flex flex-col items-center">
          <img
            src={sucessvectoricon}
            alt="Success Icon"
            className="h-20 w-20"
          />
          <h5 className="text-2xl font-bold mt-5 mb-4 text-center text-green-500">
            Your dental report will be delivered to your WhatsApp shortly. If
            you don’t receive it within 10 seconds,click here to download the
            report
          </h5>
          <h6 className="text-lg font-semibold mb-10 text-center">
            <span className="font-bold text-red-500">
              {timer > 0 ? `${timer} seconds` : "0 seconds"}
            </span>
            ,{" "}
            {timer === 0 && (
              <a
                href={reportUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 underline"
              >
                click here to download the report
              </a>
            )}
            .
          </h6>
        </div>
      </Modal>
      <section className="home-banner bg-White md:min-h-[60vh] py-8 ">
        <div className="container mx-auto">
          <div className="bg-primaryColor absolute left-0 md:w-[70%] md:h-[60vh] rounded-e-xl"></div>
          <div className="md:grid grid-cols-2 gap-5 md:min-h-[60vh] items-center">
            <div className="relative z-1 pt-7 md:pt-5 p-5 md:p-0 rounded-2xl bg-[#F3F8FF] md:bg-primaryColor">
              <h1 className="text-primaryColor md:text-White text-2xl md:text-5xl font-bold mb-5 !leading-snug">
                Straighten your smile with{" "}
                <span className="text-secondaryColor">CureBay's aligners </span>{" "}
                or braces.
              </h1>
              <p className="text-primaryColor md:text-White font-light text-sm md:text-lg">
                CureBay offers clear aligners for a comfortable, discreet way to
                straighten your teeth and boost your confidence. Get started on
                your perfect smile today!
              </p>
              {/* <button
                // onClick={() => window.open("https://curebaytotalcare.com/")}
                className="mt-5 bg-White text-primaryColor border-primaryColor border md:border-0 text-xs md:text-sm font-semibold py-2.5 px-3 md:px-5 rounded-full"
              >
                Know your oral health
              </button> */}
            </div>
            {/* <div className="relative z-1 flex justify-end mt-5 md:mt-0">
              <div className="w-full md:w-[90%] md:h-[400px]  rounded ">
                <img
                  src={dentalcareimg1}
                  alt="images"
                  className="w-full h-full  rounded-xl"
                />
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <section className="sm:bg-lightGray">
        <div className="container mx-auto py-[50px] md:py-[90px]">
          <h2 className="text-center text-xl md:text-4xl font-bold text-primaryColor leading-normal mb-[16px]">
            Know Your <span className="text-secondaryColor">Oral Health </span>{" "}
            for free
          </h2>
          <p className=" text-center sm:text-2xl text-lg font-normal text-[#676C80] sm:mb-[40px] mb-[20px]">
            Upload photos & get an instant oral health report
          </p>
          <div className="md:w-[70%] mx-auto grid md:grid-cols-3 gap-5">
            <div className="rounded-xl ">
              <div className="mb-5">
                {/* {fileDataURL?.length ? (
                  <> */}
                {file1 ? (
                  <img
                    alt="images"
                    className="sm:w-full w-[35%] ml-4 sm:ml-auto sm:h-[250px] h-auto"
                    src={file1}
                  />
                ) : (
                  <img
                    alt="images"
                    className="sm:w-full w-[35%] ml-4 sm:ml-auto"
                    src={dentalcareimg2}
                  />
                )}
              </div>
              <div className="bg-White sm:common-shadow  px-4 sm:py-4 pt-1 rounded-xl">
                <h5 className="text-[#373737] text-xl font-semibold mb-3 ">
                  Front teeth
                </h5>
                <p className="text-textGray sm:text-base text-sm">
                  To help us understand the condition of your upper and lower
                  front teeth (incisors and canines), please take a close-up
                  picture of your teeth like the one on the top. 
                </p>
                <button
                  onClick={() => {
                    clicktype1();
                  }}
                  className="text-[#067ad0]"
                >
                  Upload Image
                  <i
                    className="fa fa-chevron-right text-[12px] pl-2"
                    aria-hidden="true"
                  ></i>
                </button>
                <input
                  ref={hiddenFileInput1}
                  type="file"
                  accept="image/jpeg, image/png"
                  onChange={changeHandler1}
                  className="hidden"
                />
              </div>
            </div>
            <div className=" rounded-xl ">
              <div className="mb-5">
                {file2 ? (
                  <img
                    alt="images"
                    className="sm:w-full w-[35%] ml-4 sm:ml-auto sm:h-[250px] h-auto"
                    src={file2}
                  />
                ) : (
                  <img
                    alt="images"
                    className="sm:w-full w-[35%] ml-4 sm:ml-auto"
                    src={dentalcareimg3}
                  />
                )}
              </div>
              <div className="bg-White sm:common-shadow  px-4 sm:py-4 pt-1 rounded-xl">
                <h5 className="text-[#373737] text-xl font-semibold mb-3 ">
                  Upper teeth
                </h5>
                <p className="text-textGray sm:text-base text-sm">
                  To help us understand the condition of your upper teeth
                  (molars and premolars), please take a close-up picture of your
                  upper teeth like the one on the top.
                </p>
                <button
                  onClick={() => {
                    clicktype2();
                  }}
                  className="text-[#067ad0]"
                >
                  Upload Image
                  <i
                    className="fa fa-chevron-right text-[12px] pl-2"
                    aria-hidden="true"
                  ></i>
                </button>
                <input
                  ref={hiddenFileInput2}
                  type="file"
                  accept="image/jpeg, image/png"
                  onChange={changeHandler2}
                  className="hidden"
                />
              </div>
            </div>
            <div className="rounded-xl ">
              <div className="mb-5">
                {file3 ? (
                  <img
                    alt="images"
                    className="sm:w-full w-[35%] ml-4 sm:ml-auto sm:h-[250px] h-auto"
                    src={file3}
                  />
                ) : (
                  <img
                    alt="images"
                    className="sm:w-full w-[35%] ml-4 sm:ml-auto"
                    src={dentalcareimg4}
                  />
                )}
              </div>
              <div className="bg-White sm:common-shadow  px-4 sm:py-4 pt-1 rounded-xl">
                <h5 className="text-[#373737] text-xl font-semibold mb-3 ">
                  Lower teeth
                </h5>
                <p className="text-textGray sm:text-base text-sm">
                  To help us understand the condition of your lower teeth
                  (molars and premolars), please take a close-up picture of your
                  lower teeth like the one on the top.
                </p>
                <button
                  onClick={() => {
                    clicktype3();
                  }}
                  className="text-[#067ad0]"
                >
                  Upload Image
                  <i
                    className="fa fa-chevron-right text-[12px] pl-2"
                    aria-hidden="true"
                  ></i>
                </button>
                <input
                  ref={hiddenFileInput3}
                  type="file"
                  accept="image/jpeg, image/png"
                  onChange={changeHandler3}
                  className="hidden"
                />
              </div>
            </div>
          </div>
          <div className="text-center">
            <button
              onClick={handleFormSubmit}
              disabled={buttonLoader}
              className="w-full sm:w-auto sm:mt-10 mt-2 bg-primaryColor text-White border-primaryColor border md:border-0 text-xs md:text-base font-semibold py-[15px] px-3 md:px-[60px] rounded-full"
            >
              {buttonLoader ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      spin
                      style={{ color: "white" }}
                      color="white"
                    />
                  }
                />
              ) : (
                "Get AI report"
              )}
            </button>
          </div>
        </div>
      </section>

      <section className="sm:py-[45px] pb-[20px]">
        <h2 className="text-center text-xl md:text-4xl font-bold text-primaryColor leading-normal mb-[16px]">
          Teeth alignment issues those require an{" "}
          <span className="text-secondaryColor">Orthodontic Treatment</span>
        </h2>
        <div className="steps-container grid md:w-[90%] mx-auto sm:mt-[50px] mt-[20px] sm:px-[100px] px-[15px] sm:gap-4 gap-1 dentaldiv3">
          <Collapse
            accordion
            className="faqCollapse faqSecond2"
            expandIconPosition="end"
          >
            {faqData?.faq?.map((item, index) => (
              <Panel header={<CustomPanelHeader headerText={item.question} />}>
                <p className="text-[#676C80] font-normal sm:text-lg text-base">
                  {item.answer}
                </p>
              </Panel>
            ))}
          </Collapse>
        </div>
      </section>

      <section className="bg-lightGray py-[45px]">
        <h2 className="text-center text-xl md:text-4xl font-bold text-primaryColor leading-normal mb-[16px]">
          Why Choose CureBay for Your{" "}
          <span className="text-secondaryColor">Orthodontic Treatment? </span>
        </h2>
        <div className="steps-container grid md:w-[90%] mx-auto sm:grid-cols-2 grid-cols-1 mt-[50px] sm:px-[100px] px-[15px] sm:gap-4 gap-1">
          <div className="flex px-[5px] mb-[15px]">
            <div className="step-number sm:w-[10%] w-[15%]">
              <p className="text-[#025B91] text-3xl font-medium bg-[#97D1FF] dentalcarediv1">
                1
              </p>
            </div>
            <div className="step-content sm:w-[90%] w-[85%]">
              <h3 className="text-[#373737] sm:text-[24px] text-[17px] font-semibold">
                Expert Orthodontists
              </h3>
              <p className="text-[#666666] sm:text-[17px] text-[15px] font-normal">
                Our team consists of highly qualified and experienced
                orthodontic specialists.
              </p>
            </div>
          </div>
          <div className="flex px-[5px] mb-[15px]">
            <div className="step-number sm:w-[10%] w-[15%]">
              <p className="text-[#025B91] text-3xl font-medium bg-[#97D1FF] dentalcarediv1">
                2
              </p>
            </div>
            <div className="step-content sm:w-[90%] w-[85%]">
              <h3 className="text-[#373737] sm:text-[24px] text-[17px] font-semibold">
                Advanced Technology
              </h3>
              <p className="text-[#666666] sm:text-[17px] text-[15px] font-normal">
                Utilization of the latest orthodontic technologies for precise
                and efficient treatment.
              </p>
            </div>
          </div>
          <div className="flex px-[5px] mb-[15px]">
            <div className="step-number sm:w-[10%] w-[15%]">
              <p className="text-[#025B91] text-3xl font-medium bg-[#97D1FF] dentalcarediv1">
                3
              </p>
            </div>
            <div className="step-content sm:w-[90%] w-[85%]">
              <h3 className="text-[#373737] sm:text-[24px] text-[17px] font-semibold">
                Personalized Treatment Plans
              </h3>
              <p className="text-[#666666] sm:text-[17px] text-[15px] font-normal">
                Customized aligners and braces tailored to your unique dental
                needs.
              </p>
            </div>
          </div>
          <div className="flex px-[5px] mb-[15px]">
            <div className="step-number sm:w-[10%] w-[15%]">
              <p className="text-[#025B91] text-3xl font-medium bg-[#97D1FF] dentalcarediv1">
                4
              </p>
            </div>
            <div className="step-content sm:w-[90%] w-[85%]">
              <h3 className="text-[#373737] sm:text-[24px] text-[17px] font-semibold">
                Convenient Process
              </h3>
              <p className="text-[#666666] sm:text-[17px] text-[15px] font-normal">
                Easy photo uploads for instant oral health reports and treatment
                progress tracking.
              </p>
            </div>
          </div>
          <div className="flex px-[5px] mb-[15px]">
            <div className="step-number sm:w-[10%] w-[15%]">
              <p className="text-[#025B91] text-3xl font-medium bg-[#97D1FF] dentalcarediv1">
                5
              </p>
            </div>
            <div className="step-content sm:w-[90%] w-[85%]">
              <h3 className="text-[#373737] sm:text-[24px] text-[17px] font-semibold">
                Comfortable Solutions
              </h3>
              <p className="text-[#666666] sm:text-[17px] text-[15px] font-normal">
                Clear aligners designed for maximum comfort and minimal
                visibility.
              </p>
            </div>
          </div>

          <div className="flex px-[5px] mb-[15px]">
            <div className="step-number sm:w-[10%] w-[15%]">
              <p className="text-[#025B91] text-3xl font-medium bg-[#97D1FF] dentalcarediv1">
                6
              </p>
            </div>
            <div className="step-content sm:w-[90%] w-[85%]">
              <h3 className="text-[#373737] sm:text-[24px] text-[17px] font-semibold">
                Comprehensive Care
              </h3>
              <p className="text-[#666666] sm:text-[17px] text-[15px] font-normal">
                Full range of services from initial consultation to
                post-treatment follow-up.
              </p>
            </div>
          </div>

          <div className="flex px-[5px] mb-[15px]">
            <div className="step-number sm:w-[10%] w-[15%]">
              <p className="text-[#025B91] text-3xl font-medium bg-[#97D1FF] dentalcarediv1">
                7
              </p>
            </div>
            <div className="step-content sm:w-[90%] w-[85%]">
              <h3 className="text-[#373737] sm:text-[24px] text-[17px] font-semibold">
                Affordable Options
              </h3>
              <p className="text-[#666666] sm:text-[17px] text-[15px] font-normal">
                Competitive pricing with flexible payment plans to suit your
                budget.
              </p>
            </div>
          </div>

          <div className="flex px-[5px] mb-[15px]">
            <div className="step-number sm:w-[10%] w-[15%]">
              <p className="text-[#025B91] text-3xl font-medium bg-[#97D1FF] dentalcarediv1">
                8
              </p>
            </div>
            <div className="step-content sm:w-[90%] w-[85%]">
              <h3 className="text-[#373737] sm:text-[24px] text-[17px] font-semibold">
                Positive Results
              </h3>
              <p className="text-[#666666] sm:text-[17px] text-[15px] font-normal">
                Proven track record of successful treatments and satisfied
                patients.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layouts>
  );
}

export default Dentalcare;

// import React, { useState, useEffect, useRef } from "react";
// import Layouts from "../../Layouts";
// import PersonalizedAssistant from "../../../Assets/Images/Icons/PersonalizedAssistant.svg";
// import EmotionalSupport from "../../../Assets/Images/Icons/EmotionalSupport.svg";
// import Continuous from "../../../Assets/Images/Icons/Continuous.svg";
// import Comperhensive from "../../../Assets/Images/Icons/Comperhensive.svg";
// import PieceMind from "../../../Assets/Images/Icons/PieceMind.svg";
// import SubHeader from "../../Shared/SearchBar";
// import dentalcareimg1 from "../../../Assets/Images/dentalcareimg1.png";
// import dentalcareimg2 from "../../../Assets/Images/dentalcareimg2.png";
// import dentalcareimg3 from "../../../Assets/Images/dentalcareimg3.png";
// import dentalcareimg4 from "../../../Assets/Images/dentalcareimg4.png";
// import { Collapse } from "antd";
// import Arrowimage from "../../../Assets/Images/Icons/linearrow.png";
// import http from "../../../Redux/Services/http-common";
// import { useDispatch, useSelector } from "react-redux";
// import { useHistory, useLocation } from "react-router-dom";
// import { ToastContainer, toast } from "react-toastify";
// import { checkIsLoggedIn } from "../../../Redux/Action/userActions";
// import { LoadingOutlined } from '@ant-design/icons';
// import {Spin } from 'antd';
// const { Panel } = Collapse;

// const faqData = {
//   faq: [
//     {
//       question: "Crowding of teeth",
//       answer:
//         "Crowding occurs when there’s insufficient space in the jaw for all teeth, causing them to overlap or misalign. It can result from genetics, early or late loss of baby teeth, or habits like thumb-sucking. Treatment typically involves orthodontics, such as braces or aligners, to realign teeth and improve oral hygiene, appearance, and function.",
//     },
//     {
//       question: "Open bite",
//       answer:
//         "Crowding occurs when there’s insufficient space in the jaw for all teeth, causing them to overlap or misalign. It can result from genetics, early or late loss of baby teeth, or habits like thumb-sucking. Treatment typically involves orthodontics, such as braces or aligners, to realign teeth and improve oral hygiene, appearance, and function.",
//     },
//     {
//       question: "Protruded teeth",
//       answer:
//         "Crowding occurs when there’s insufficient space in the jaw for all teeth, causing them to overlap or misalign. It can result from genetics, early or late loss of baby teeth, or habits like thumb-sucking. Treatment typically involves orthodontics, such as braces or aligners, to realign teeth and improve oral hygiene, appearance, and function.",
//     },
//     {
//       question: "Cross bite",
//       answer:
//         "Crowding occurs when there’s insufficient space in the jaw for all teeth, causing them to overlap or misalign. It can result from genetics, early or late loss of baby teeth, or habits like thumb-sucking. Treatment typically involves orthodontics, such as braces or aligners, to realign teeth and improve oral hygiene, appearance, and function.",
//     },
//     {
//       question: "Under bite",
//       answer:
//         "Crowding occurs when there’s insufficient space in the jaw for all teeth, causing them to overlap or misalign. It can result from genetics, early or late loss of baby teeth, or habits like thumb-sucking. Treatment typically involves orthodontics, such as braces or aligners, to realign teeth and improve oral hygiene, appearance, and function.",
//     },
//     {
//       question: "Missing teeth",
//       answer:
//         "Crowding occurs when there’s insufficient space in the jaw for all teeth, causing them to overlap or misalign. It can result from genetics, early or late loss of baby teeth, or habits like thumb-sucking. Treatment typically involves orthodontics, such as braces or aligners, to realign teeth and improve oral hygiene, appearance, and function.",
//     },
//     {
//       question: "Deep bite teeth",
//       answer:
//         "Crowding occurs when there’s insufficient space in the jaw for all teeth, causing them to overlap or misalign. It can result from genetics, early or late loss of baby teeth, or habits like thumb-sucking. Treatment typically involves orthodontics, such as braces or aligners, to realign teeth and improve oral hygiene, appearance, and function.",
//     },
//   ],
// };

// const CustomPanelHeader = ({ headerText }) => {
//   return (
//     <div className="flex gap-4 items-center">
//       {/* <img src={Arrowimage} alt="images" className="h-[20px]" /> */}
//       <span className="text-[#373737] sm:text-2xl text-lg font-medium">
//         {headerText}
//       </span>
//     </div>
//   );
// };
// const encodeBase64File = (file) => {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = (error) => reject(error);
//   });
// };

// function Dentalcare() {
//   const hiddenFileInput1 = useRef(null);
//   const hiddenFileInput2 = useRef(null);
//   const hiddenFileInput3 = useRef(null);
//   const userData = useSelector((state) => state.authReducer.patientData);
//   console.log(userData, "iuhklnlk");
//   const [error, setError] = useState(null);
//   const [b64File1, setB64File1] = useState("");
//   const [file1, setFile1] = useState("");
//   const [b64File2, setB64File2] = useState("");
//   const [file2, setFile2] = useState("");
//   const [b64File3, setB64File3] = useState("");
//   const [file3, setFile3] = useState("");
//   const [buttonLoader, setButtonLoader] = useState(false)
//   const [filetype1, setFiletype1] = useState("");
//   const [filetype2, setFiletype2] = useState("");
//   const [filetype3, setFiletype3] = useState("");
//   const history = useHistory();
//   const location = useLocation();
//   const search = useLocation().search;
//   const search_query = new URLSearchParams(search).get("source");
//   const changeHandler1 = async (e) => {
//     const file = e.target.files[0]; // Only allow one file
//     if (!file) return;

//     const fileType = file.type.split("/")[1].toUpperCase();
//     setFiletype1(fileType);
//     const validFileTypes = ["JPG", "PNG", "JPEG"];

//     if (!validFileTypes.includes(fileType)) {
//       setError("Please upload JPEG, JPG, PNG formats only");
//       setB64File1(""); // Clear the previous image if error occurs
//       return;
//     }

//     const fileSizeMB = file.size / 1000000;
//     if (fileSizeMB > 5) {
//       setError("Please upload a photo smaller than 5MB");
//       setB64File1(""); // Clear the previous image if error occurs
//       return;
//     }

//     const base64File = await encodeBase64File(file);
//     setFile1(base64File);
//     setB64File1(base64File.split(",")[1]);

//     setError(null);
//   };

//   const changeHandler2 = async (e) => {
//     const file = e.target.files[0]; // Only allow one file
//     if (!file) return;

//     const fileType = file.type.split("/")[1].toUpperCase();
//     setFiletype2(fileType);
//     const validFileTypes = ["JPG", "PNG", "JPEG"];

//     if (!validFileTypes.includes(fileType)) {
//       setError("Please upload JPEG, JPG, PNG formats only");
//       setB64File2(""); // Clear the previous image if error occurs
//       return;
//     }

//     const fileSizeMB = file.size / 1000000;
//     if (fileSizeMB > 5) {
//       setError("Please upload a photo smaller than 5MB");
//       setB64File2(""); // Clear the previous image if error occurs
//       return;
//     }

//     const base64File = await encodeBase64File(file);
//     setFile2(base64File);
//     setB64File2(base64File.split(",")[1]);

//     setError(null);
//   };

//   const changeHandler3 = async (e) => {
//     const file = e.target.files[0]; // Only allow one file
//     if (!file) return;

//     const fileType = file.type.split("/")[1].toUpperCase();
//     const validFileTypes = ["JPG", "PNG", "JPEG"];
//     setFiletype3(fileType);
//     if (!validFileTypes.includes(fileType)) {
//       setError("Please upload JPEG, JPG, PNG formats only");
//       setB64File3(""); // Clear the previous image if error occurs
//       return;
//     }

//     const fileSizeMB = file.size / 1000000;
//     if (fileSizeMB > 5) {
//       setError("Please upload a photo smaller than 5MB");
//       setB64File3(""); // Clear the previous image if error occurs
//       return;
//     }

//     const base64File = await encodeBase64File(file);
//     setFile3(base64File);
//     setB64File3(base64File.split(",")[1]);

//     setError(null);
//   };

//   const clicktype1 = () => {
//     hiddenFileInput1.current.click();
//   };

//   const clicktype2 = () => {
//     hiddenFileInput2.current.click();
//   };
//   const clicktype3 = () => {
//     hiddenFileInput3.current.click();
//   };

//   console.log(process.env.REACT_APP_NEWBASEURL,process.env,"environ")

//   const handleFormSubmit = async () => {
//     try{
// const modified = process.env.REACT_APP_NEWBASEURL === 'https://common-stage.curebay.in/cs/' ? 'XDLFMQXG290724' : process.env.REACT_APP_NEWBASEURL === 'https://common.curebay.com/cs/' ? 'BZGTWCGK290724' : '';

//     if (Object.keys(userData).length === 0) {
//       history.push({pathname: "/", state: {from: location.pathname}});
//     } else {
//       if (
//         b64File1.length === 0 ||
//         b64File2.length === 0 ||
//         b64File3.length === 0
//       ) {
//         // alert('All files must be provided.');
//         toast("All files must be provided.");
//         return false; // Prevent form submission
//       }
//       setButtonLoader(true)
//       const payload = {
//         labTestCode: "EL52R",
//         front_Teeth: b64File1,
//         front_TeethType: filetype1,
//         upper_Teeth: b64File2,
//         upper_TeethType: filetype2,
//         lower_Teeth: b64File3,
//         lower_TeethType: filetype3,
//         orderId: null,
//         patientId: userData.code,
//         patientName: `${userData.firstName} ${userData.lastName}`,
//         modifiedBy: modified,
//         createdBy: modified,
//         eclinicName1: null,
//         eclinicName2: null,
//         eclinicName3: null,
//         eclinicAddress1: null,
//         eclinicAddress2: null,
//         eclinicAddress3: null,
//         eclinicImgLink1: null,
//         eclinicImgLink2: null,
//         eclinicImgLink3: null,
//         status: 1,
//         // sourceChannel:"B2C"
//       };
//       console.log(payload, "payload");

//       const Response = await http.post(
//        `${process.env.REACT_APP_NEWBASEURL}PatientLabTestImageUpload/addPatientLabTestImageUpload`,
//         // `https://common.curebay.com/cs/PatientLabTestImageUpload/addPatientLabTestImageUpload`,
//         payload
//       );
//       console.log(Response, "Response");
//       // {"Status":"Created Successfully","ReportId":"6afa156b-9649-43a4-901d-33bb48919c86","ReportStatus":"processing"}
//       setButtonLoader(false)
//       if (Response.status == 200) {
//       setButtonLoader(false)
//         toast(
//           "Thank you for uploading. You will receive the dental health report shortly."
//         );
//       } else {
//       setButtonLoader(false)
//       }
//     }
//   } catch (e) {
//     setButtonLoader(false)
//   }
//   };
//   if(search_query == "qrcode" && !checkIsLoggedIn()){
//     history.push({pathname: "/", state: {from: location.pathname ,isLoginModalOpen: true }});
//     return;
//   }
//   return (
//     <Layouts>
//       <SubHeader />
//       <ToastContainer />
//       <section className="home-banner bg-White md:min-h-[60vh] py-8 ">
//         <div className="container mx-auto">
//           <div className="bg-primaryColor absolute left-0 md:w-[70%] md:h-[60vh] rounded-e-xl"></div>
//           <div className="md:grid grid-cols-2 gap-5 md:min-h-[60vh] items-center">
//             <div className="relative z-1 pt-7 md:pt-5 p-5 md:p-0 rounded-2xl bg-[#F3F8FF] md:bg-primaryColor">
//               <h1 className="text-primaryColor md:text-White text-2xl md:text-5xl font-bold mb-5 !leading-snug">
//                 Straighten your smile with{" "}
//                 <span className="text-secondaryColor">CureBay's aligners </span>{" "}
//                 or braces.
//               </h1>
//               <p className="text-primaryColor md:text-White font-light text-sm md:text-lg">
//                 CureBay offers clear aligners for a comfortable, discreet way to
//                 straighten your teeth and boost your confidence. Get started on
//                 your perfect smile today!
//               </p>
//               {/* <button
//                 // onClick={() => window.open("https://curebaytotalcare.com/")}
//                 className="mt-5 bg-White text-primaryColor border-primaryColor border md:border-0 text-xs md:text-sm font-semibold py-2.5 px-3 md:px-5 rounded-full"
//               >
//                 Know your oral health
//               </button> */}
//             </div>
//             {/* <div className="relative z-1 flex justify-end mt-5 md:mt-0">
//               <div className="w-full md:w-[90%] md:h-[400px]  rounded ">
//                 <img
//                   src={dentalcareimg1}
//                   alt="images"
//                   className="w-full h-full  rounded-xl"
//                 />
//               </div>
//             </div> */}
//           </div>
//         </div>
//       </section>
//       <section className="sm:bg-lightGray">
//         <div className="container mx-auto py-[50px] md:py-[90px]">
//           <h2 className="text-center text-xl md:text-4xl font-bold text-primaryColor leading-normal mb-[16px]">
//             Know Your <span className="text-secondaryColor">Oral Health </span>{" "}
//             for free
//           </h2>
//           <p className=" text-center sm:text-2xl text-lg font-normal text-[#676C80] sm:mb-[40px] mb-[20px]">
//             Upload photos & get an instant oral health report
//           </p>
//           <div className="md:w-[70%] mx-auto grid md:grid-cols-3 gap-5">
//             <div className="rounded-xl ">
//               <div className="mb-5">
//                 {/* {fileDataURL?.length ? (
//                   <> */}
//                 {file1 ? (
//                   <img
//                     alt="images"
//                     className="sm:w-full w-[35%] ml-4 sm:ml-auto sm:h-[250px] h-auto"
//                     src={file1}
//                   />
//                 ) : (
//                   <img
//                     alt="images"
//                     className="sm:w-full w-[35%] ml-4 sm:ml-auto"
//                     src={dentalcareimg2}
//                   />
//                 )}
//               </div>
//               <div className="bg-White sm:common-shadow  px-4 sm:py-4 pt-1 rounded-xl">
//                 <h5 className="text-[#373737] text-xl font-semibold mb-3 ">
//                   Front teeth
//                 </h5>
//                 <p className="text-textGray sm:text-base text-sm">
//                   To help us understand the condition of your lower front teeth
//                   (canine) and back teeth (molars), please take a close-up
//                   picture of your lower teeth like the one on the top.
//                 </p>
//                 <button
//                   onClick={() => {
//                     clicktype1();
//                   }}
//                   className="text-[#067ad0]"
//                 >
//                   Upload Image
//                   <i
//                     className="fa fa-chevron-right text-[12px] pl-2"
//                     aria-hidden="true"
//                   ></i>
//                 </button>
//                 <input
//                   ref={hiddenFileInput1}
//                   type="file"
//                   accept="image/jpeg, image/png"
//                   onChange={changeHandler1}
//                   className="hidden"
//                 />
//               </div>
//             </div>
//             <div className=" rounded-xl ">
//               <div className="mb-5">
//                 {file2 ? (
//                   <img
//                     alt="images"
//                     className="sm:w-full w-[35%] ml-4 sm:ml-auto sm:h-[250px] h-auto"
//                     src={file2}
//                   />
//                 ) : (
//                   <img
//                     alt="images"
//                     className="sm:w-full w-[35%] ml-4 sm:ml-auto"
//                     src={dentalcareimg3}
//                   />
//                 )}
//               </div>
//               <div className="bg-White sm:common-shadow  px-4 sm:py-4 pt-1 rounded-xl">
//                 <h5 className="text-[#373737] text-xl font-semibold mb-3 ">
//                   Upper teeth
//                 </h5>
//                 <p className="text-textGray sm:text-base text-sm">
//                   To help us understand the condition of your lower front teeth
//                   (canine) and back teeth (molars), please take a close-up
//                   picture of your lower teeth like the one on the top.
//                 </p>
//                 <button
//                   onClick={() => {
//                     clicktype2();
//                   }}
//                   className="text-[#067ad0]"
//                 >
//                   Upload Image
//                   <i
//                     className="fa fa-chevron-right text-[12px] pl-2"
//                     aria-hidden="true"
//                   ></i>
//                 </button>
//                 <input
//                   ref={hiddenFileInput2}
//                   type="file"
//                   accept="image/jpeg, image/png"
//                   onChange={changeHandler2}
//                   className="hidden"
//                 />
//               </div>
//             </div>
//             <div className="rounded-xl ">
//               <div className="mb-5">
//                 {file3 ? (
//                   <img
//                     alt="images"
//                     className="sm:w-full w-[35%] ml-4 sm:ml-auto sm:h-[250px] h-auto"
//                     src={file3}
//                   />
//                 ) : (
//                   <img
//                     alt="images"
//                     className="sm:w-full w-[35%] ml-4 sm:ml-auto"
//                     src={dentalcareimg4}
//                   />
//                 )}
//               </div>
//               <div className="bg-White sm:common-shadow  px-4 sm:py-4 pt-1 rounded-xl">
//                 <h5 className="text-[#373737] text-xl font-semibold mb-3 ">
//                   Lower teeth
//                 </h5>
//                 <p className="text-textGray sm:text-base text-sm">
//                   To help us understand the condition of your lower front teeth
//                   (canine) and back teeth (molars), please take a close-up
//                   picture of your lower teeth like the one on the top.
//                 </p>
//                 <button
//                   onClick={() => {
//                     clicktype3();
//                   }}
//                   className="text-[#067ad0]"
//                 >
//                   Upload Image
//                   <i
//                     className="fa fa-chevron-right text-[12px] pl-2"
//                     aria-hidden="true"
//                   ></i>
//                 </button>
//                 <input
//                   ref={hiddenFileInput3}
//                   type="file"
//                   accept="image/jpeg, image/png"
//                   onChange={changeHandler3}
//                   className="hidden"
//                 />
//               </div>
//             </div>
//           </div>
//           <div className="text-center">
//             <button
//               onClick={handleFormSubmit}
//               disabled = {buttonLoader}
//               className="w-full sm:w-auto sm:mt-10 mt-2 bg-primaryColor text-White border-primaryColor border md:border-0 text-xs md:text-base font-semibold py-[15px] px-3 md:px-[60px] rounded-full"
//             >
//               {buttonLoader ? <Spin indicator={<LoadingOutlined spin style={{color: "white"}} color="white" />} /> : "Get AI report"}
//             </button>
//           </div>
//         </div>
//       </section>

//       <section className="sm:py-[45px] pb-[20px]">
//         <h2 className="text-center text-xl md:text-4xl font-bold text-primaryColor leading-normal mb-[16px]">
//           Teeth alignment issues those require an{" "}
//           <span className="text-secondaryColor">Orthodontic Treatment</span>
//         </h2>
//         <div className="steps-container grid md:w-[90%] mx-auto sm:mt-[50px] mt-[20px] sm:px-[100px] px-[15px] sm:gap-4 gap-1 dentaldiv3">
//           <Collapse
//             accordion
//             className="faqCollapse faqSecond2"
//             expandIconPosition="end"
//           >
//             {faqData?.faq?.map((item, index) => (
//               <Panel header={<CustomPanelHeader headerText={item.question} />}>
//                 <p className="text-[#676C80] font-normal sm:text-lg text-base">
//                   {item.answer}
//                 </p>
//               </Panel>
//             ))}
//           </Collapse>
//         </div>
//       </section>

//       <section className="bg-lightGray py-[45px]">
//         <h2 className="text-center text-xl md:text-4xl font-bold text-primaryColor leading-normal mb-[16px]">
//           Why Choose CureBay for Your{" "}
//           <span className="text-secondaryColor">Orthodontic Treatment? </span>
//         </h2>
//         <div className="steps-container grid md:w-[90%] mx-auto sm:grid-cols-2 grid-cols-1 mt-[50px] sm:px-[100px] px-[15px] sm:gap-4 gap-1">
//           <div className="flex px-[5px] mb-[15px]">
//             <div className="step-number sm:w-[10%] w-[15%]">
//               <p className="text-[#025B91] text-3xl font-medium bg-[#97D1FF] dentalcarediv1">
//                 1
//               </p>
//             </div>
//             <div className="step-content sm:w-[90%] w-[85%]">
//               <h3 className="text-[#373737] sm:text-[24px] text-[17px] font-semibold">
//                 Expert Orthodontists
//               </h3>
//               <p className="text-[#666666] sm:text-[17px] text-[15px] font-normal">
//                 Our team consists of highly qualified and experienced
//                 orthodontic specialists.
//               </p>
//             </div>
//           </div>
//           <div className="flex px-[5px] mb-[15px]">
//             <div className="step-number sm:w-[10%] w-[15%]">
//               <p className="text-[#025B91] text-3xl font-medium bg-[#97D1FF] dentalcarediv1">
//                 2
//               </p>
//             </div>
//             <div className="step-content sm:w-[90%] w-[85%]">
//               <h3 className="text-[#373737] sm:text-[24px] text-[17px] font-semibold">
//                 Advanced Technology
//               </h3>
//               <p className="text-[#666666] sm:text-[17px] text-[15px] font-normal">
//                 Utilization of the latest orthodontic technologies for precise
//                 and efficient treatment.
//               </p>
//             </div>
//           </div>
//           <div className="flex px-[5px] mb-[15px]">
//             <div className="step-number sm:w-[10%] w-[15%]">
//               <p className="text-[#025B91] text-3xl font-medium bg-[#97D1FF] dentalcarediv1">
//                 3
//               </p>
//             </div>
//             <div className="step-content sm:w-[90%] w-[85%]">
//               <h3 className="text-[#373737] sm:text-[24px] text-[17px] font-semibold">
//                 Personalized Treatment Plans
//               </h3>
//               <p className="text-[#666666] sm:text-[17px] text-[15px] font-normal">
//                 Customized aligners and braces tailored to your unique dental
//                 needs.
//               </p>
//             </div>
//           </div>
//           <div className="flex px-[5px] mb-[15px]">
//             <div className="step-number sm:w-[10%] w-[15%]">
//               <p className="text-[#025B91] text-3xl font-medium bg-[#97D1FF] dentalcarediv1">
//                 4
//               </p>
//             </div>
//             <div className="step-content sm:w-[90%] w-[85%]">
//               <h3 className="text-[#373737] sm:text-[24px] text-[17px] font-semibold">
//                 Convenient Process
//               </h3>
//               <p className="text-[#666666] sm:text-[17px] text-[15px] font-normal">
//                 Easy photo uploads for instant oral health reports and treatment
//                 progress tracking.
//               </p>
//             </div>
//           </div>
//           <div className="flex px-[5px] mb-[15px]">
//             <div className="step-number sm:w-[10%] w-[15%]">
//               <p className="text-[#025B91] text-3xl font-medium bg-[#97D1FF] dentalcarediv1">
//                 5
//               </p>
//             </div>
//             <div className="step-content sm:w-[90%] w-[85%]">
//               <h3 className="text-[#373737] sm:text-[24px] text-[17px] font-semibold">
//                 Comfortable Solutions
//               </h3>
//               <p className="text-[#666666] sm:text-[17px] text-[15px] font-normal">
//                 Clear aligners designed for maximum comfort and minimal
//                 visibility.
//               </p>
//             </div>
//           </div>

//           <div className="flex px-[5px] mb-[15px]">
//             <div className="step-number sm:w-[10%] w-[15%]">
//               <p className="text-[#025B91] text-3xl font-medium bg-[#97D1FF] dentalcarediv1">
//                 6
//               </p>
//             </div>
//             <div className="step-content sm:w-[90%] w-[85%]">
//               <h3 className="text-[#373737] sm:text-[24px] text-[17px] font-semibold">
//                 Comprehensive Care
//               </h3>
//               <p className="text-[#666666] sm:text-[17px] text-[15px] font-normal">
//                 Full range of services from initial consultation to
//                 post-treatment follow-up.
//               </p>
//             </div>
//           </div>

//           <div className="flex px-[5px] mb-[15px]">
//             <div className="step-number sm:w-[10%] w-[15%]">
//               <p className="text-[#025B91] text-3xl font-medium bg-[#97D1FF] dentalcarediv1">
//                 7
//               </p>
//             </div>
//             <div className="step-content sm:w-[90%] w-[85%]">
//               <h3 className="text-[#373737] sm:text-[24px] text-[17px] font-semibold">
//                 Affordable Options
//               </h3>
//               <p className="text-[#666666] sm:text-[17px] text-[15px] font-normal">
//                 Competitive pricing with flexible payment plans to suit your
//                 budget.
//               </p>
//             </div>
//           </div>

//           <div className="flex px-[5px] mb-[15px]">
//             <div className="step-number sm:w-[10%] w-[15%]">
//               <p className="text-[#025B91] text-3xl font-medium bg-[#97D1FF] dentalcarediv1">
//                 8
//               </p>
//             </div>
//             <div className="step-content sm:w-[90%] w-[85%]">
//               <h3 className="text-[#373737] sm:text-[24px] text-[17px] font-semibold">
//                 Positive Results
//               </h3>
//               <p className="text-[#666666] sm:text-[17px] text-[15px] font-normal">
//                 Proven track record of successful treatments and satisfied
//                 patients.
//               </p>
//             </div>
//           </div>
//         </div>
//       </section>
//     </Layouts>
//   );
// }

// export default Dentalcare;
