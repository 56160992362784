import React, { useEffect, useState, useRef } from "react";
import { Spin } from "antd";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Header from "../../Shared/Header";
import { secureStorage } from "../../../Redux/Reducer/authReducer";
import Click from "../../../Assets/Images/click.svg";

const VideoCaptureComponent = ({ formData }) => {
  const [countdown, setCountdown] = useState(5);
  const [isRecording, setIsRecording] = useState(false);
  const [videoBlob, setVideoBlob] = useState(null);
  const [videoURL, setVideoURL] = useState(null);
  const [isRecordingCounter, setIsRecordingCounter] = useState(30);
  const [hasCameraAccess, setHasCameraAccess] = useState(false);
  const [isPreparingDataLoading, setIsPreparingDataLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const videoRef = useRef(null);
  const mediaStreamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const countdownIntervalRef = useRef(null);
  const recordingIntervalRef = useRef(null);

  const history = useHistory();
  const [smartVitalsData, setSmartVitalsData] = useState(null);
  const s_token = secureStorage.getItem("token");
  const storedData = JSON.parse(localStorage.getItem("patientInfo"));

  // Initialize Camera when Component Mounts
  useEffect(() => {
    initializeCamera();

    return () => {
      // Clean up camera and intervals on unmount
      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach((track) => track.stop());
      }
      if (countdownIntervalRef.current) clearInterval(countdownIntervalRef.current);
      if (recordingIntervalRef.current) clearInterval(recordingIntervalRef.current);
    };
  }, []);

  // Ensure videoRef gets the stream once initialized
  useEffect(() => {
    if (mediaStreamRef.current && videoRef.current) {
      videoRef.current.srcObject = mediaStreamRef.current;
      videoRef.current
        .play()
        .then(() => console.log("Video playback started successfully."))
        .catch((error) => console.error("Error starting video playback:", error));
    }
  }, [mediaStreamRef.current]);

  const initializeCamera = async () => {
    try {
      console.log("Initializing camera...");
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { width: 1280, height: 720 },
        audio: false,
      });
      mediaStreamRef.current = stream;

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current
          .play()
          .then(() => console.log("Video playback started after initialization."))
          .catch((error) => console.error("Error starting video playback:", error));
      }

      setHasCameraAccess(true);
      startCountdown(); // Start countdown only after camera access is granted
    } catch (error) {
      console.error("Error accessing camera:", error);
      alert("Could not access the camera. Please check your permissions.");
      setHasCameraAccess(false);
    }
  };

  const startCountdown = () => {
    if (countdownIntervalRef.current) {
      clearInterval(countdownIntervalRef.current);
    }

    setCountdown(5);
    countdownIntervalRef.current = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(countdownIntervalRef.current);
          countdownIntervalRef.current = null;
          startRecording();
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const startRecording = () => {
    if (!mediaStreamRef.current) {
      console.error("No media stream available for recording.");
      return;
    }

    if (isRecording) {
      console.warn("Already recording.");
      return;
    }

    try {
      const isMp4Supported = MediaRecorder.isTypeSupported("video/mp4");
      const mimeType = isMp4Supported ? "video/mp4" : "video/webm";
      const options = { mimeType };

      const recorder = new MediaRecorder(mediaStreamRef.current, options);
      mediaRecorderRef.current = recorder;

      const chunks = [];
      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) chunks.push(event.data);
      };

      recorder.onstart = () => {
        setIsRecording(true);
        setIsRecordingCounter(30);

        // Re-assign the video element's stream during recording to ensure it displays
        if (videoRef.current) {
          videoRef.current.srcObject = mediaStreamRef.current;
          videoRef.current
            .play()
            .then(() => console.log("Video playback started during recording."))
            .catch((error) => console.error("Error playing video during recording:", error));
        }
      };

      recorder.onstop = async () => {
        setIsRecording(false);

        const blob = new Blob(chunks, { type: mimeType });
        setVideoBlob(blob);

        const videoUrl = URL.createObjectURL(blob);
        setVideoURL(videoUrl);

        if (mediaStreamRef.current) {
          mediaStreamRef.current.getTracks().forEach((track) => track.stop());
          mediaStreamRef.current = null;
        }

        setIsPreparingDataLoading(true);

        if (!isSubmitted) {
          setIsSubmitted(true);
          await onSubmit(blob);
        }

        if (recordingIntervalRef.current) {
          clearInterval(recordingIntervalRef.current);
          recordingIntervalRef.current = null;
        }
      };

      recorder.onerror = (event) => {
        console.error("Recorder error:", event.error);
        alert("An error occurred during recording. Please try again.");
        setIsRecording(false);
        setIsPreparingDataLoading(false);
      };

      recorder.start();

      recordingIntervalRef.current = setInterval(() => {
        setIsRecordingCounter((prev) => {
          if (prev <= 1) {
            clearInterval(recordingIntervalRef.current);
            recordingIntervalRef.current = null;
            if (recorder.state !== "inactive") {
              recorder.stop();
            }
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    } catch (error) {
      console.error("Error starting recording:", error);
      alert("Could not start video recording.");
    }
  };

  const onSubmit = async (blob) => {
    if (!blob || isSubmitted || isPreparingDataLoading) {
      return;
    }

    const formDataPayload = new FormData();
    formDataPayload.append(
      "name",
      `${storedData?.data?.firstName} ${storedData?.data?.lastName}`
    );
    formDataPayload.append("age", calculateAge(storedData?.data?.dob));
    formDataPayload.append("gender", storedData?.data?.gender);
    formDataPayload.append("height", parseInt(formData?.height, 10));
    formDataPayload.append("height_unit", formData?.heightUnit);
    formDataPayload.append("weight", parseInt(formData?.weight, 10));
    formDataPayload.append("weight_unit", formData?.weightUnit);
    formDataPayload.append("smoke", formData?.smoke);
    formDataPayload.append("diabetes", formData?.diabetes);
    formDataPayload.append("video", blob, "recorded-video.mp4");

    try {
      const response = await axios.post(
        "https://contactlessvitals-stage.curebay.in/process-video/",
        formDataPayload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${s_token}`,
          },
        }
      );

      if (response.status === 200) {
        setIsPreparingDataLoading(false);
        setSmartVitalsData(response.data.data);
        setVideoBlob(null);
        setVideoURL(null);
        history.push({
          pathname: "/smart-vital-report",
          state: { responseData: response.data.data },
        });
      } else {
        throw new Error("API response not OK");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setIsPreparingDataLoading(false);
      setIsSubmitted(false);
    }
  };

  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    if (
      today.getMonth() < birthDate.getMonth() ||
      (today.getMonth() === birthDate.getMonth() &&
        today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  if (!hasCameraAccess) {
    return (
      <div className="flex items-center justify-center" style={{ height: "90vh" }}>
        <p>
          Camera access is denied. Please enable camera access in your browser
          settings.
        </p>
        <button onClick={initializeCamera} className="mt-4 px-4 py-2 bg-primaryColor text-white">
          Retry Access
        </button>
      </div>
    );
  }

  return (
    <>
      <Header />
      <div style={{ textAlign: "center", padding: "20px" }}>
        {!isPreparingDataLoading && (
          <>
            <div className="flex flex-col items-center justify-center ">
              <div className="w-[300px] h-[300px] rounded-lg overflow-hidden mt-28">
                <video
                  ref={videoRef}
                  autoPlay
                  playsInline
                  className="w-full h-full object-cover bg-black"
                ></video>
              </div>
              {!isRecording && countdown > 0 ? (
                <div className="text-center flex flex-col justify-center items-center">
                  <div className="bg-[#f3f8ff] rounded-full w-20 sm:h-20 h-14 flex items-center justify-center mb-4">
                    <img src={Click} alt="Finger Icon" className="w-12 h-12" />
                  </div>
                  <h2 className="text-lg font-semibold text-primaryColor mb-2">
                    Please look straight into the camera
                  </h2>
                  <div className="flex items-center justify-center space-x-2">
                    <div className="w-4 h-4 border-2 border-[#004171] rounded-full animate-pulse"></div>
                    <p className="text-sm text-textGray">
                      Scanning starting in{" "}
                      <span className="text-secondaryColor font-semibold">
                        {countdown} sec
                      </span>
                    </p>
                  </div>
                </div>
              ) : (
                isRecording && (
                  <div className="mt-4 text-center">
                    <div className="flex items-center justify-center space-x-2">
                      <Spin />
                      <p className="text-primaryColor font-semibold">
                        Recording... Please keep your face visible in the camera
                      </p>
                    </div>
                    <p className="text-textGray mt-2">
                      Recording ends in{" "}
                      <span className="text-secondaryColor font-semibold">
                        {isRecordingCounter} seconds
                      </span>
                    </p>
                  </div>
                )
              )}
            </div>
          </>
        )}
        {isPreparingDataLoading && (
          <div className="flex flex-col items-center justify-center h-screen">
            <p>Please wait, preparing your vitals report</p>
            <Spin />
          </div>
        )}
      </div>
    </>
  );
};

export default VideoCaptureComponent;
